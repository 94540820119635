import meta from '../constants/meta'

type newsLetterSignupParams = {
    firstName: string
    email: string
}

export default function newsLetterSignup({
    firstName,
    email,
}: newsLetterSignupParams): Promise<any> {
    const formData = new FormData()
    formData.append('fields[first_name]', firstName)
    formData.append('email_address', email)
    return fetch(meta.forms.newsLetter, {
        method: 'POST',
        body: formData,
        headers: {
            accept: 'application/json',
        },
    }).then(res => res.json())
}
