import React from 'react'
import { Input, Box, Text, Stack, Heading } from '@chakra-ui/react'
import { useForm } from 'react-hook-form'

import { useMutation } from 'react-query'
import newsLetterSignup from '../../services/newsletter'
import { NiftyButton } from '../atoms/NiftyButton/NiftyButton'

export default function NewsletterForm() {
    const { register, handleSubmit, reset } = useForm()
    const { mutate, isLoading, isSuccess } = useMutation(newsLetterSignup)

    async function onSubmit(data) {
        await mutate(data)
        reset()
    }

    return (
        <Stack
            p={5}
            borderRadius="base"
            bg="blue.200"
            _dark={{ bg: "blue.700" }}
            spacing={4}
        >
            <Box textAlign="center">
                <Heading as="h2" size="md">
                    Join The Newsletter
                </Heading>
                <Text>Get more content like this right in your inbox!</Text>
            </Box>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Stack spacing={4}>
                    <Stack
                        direction={{ base: "column", md: "row" }}
                        alignItems="stretch"
                        spacing={4}
                        isolation="isolate"
                    >
                        <Stack
                            direction={{ base: "column", md: "row" }}
                            flex={1}
                        >
                            <Input
                                type="text"
                                name="firstName"
                                placeholder="First Name"
                                disabled={isLoading || isSuccess}
                                required
                                {...register("firstName", { required: true })}
                                />
                            <Input
                                type="email"
                                name="email"
                                placeholder="Email Address"
                                disabled={isLoading || isSuccess}
                                required
                                {...register("email", { required: true })}
                            />
                        </Stack>
                        <Box zIndex={5}>
                            <NiftyButton
                                width={{ base: "full", md: "auto" }}
                                colorScheme="blue"
                                type="submit"
                                disabled={isSuccess}
                            >
                                Subscribe
                            </NiftyButton>
                        </Box>
                    </Stack>
                    {isSuccess && (
                        <Text fontStyle="italic" textAlign="center">
                            Yay! you've signed up for the newsletter 🎉
                            <br/>
                            check your email to confirm your subscription.
                        </Text>
                    )}
                </Stack>
            </form>
        </Stack>
    )
}
