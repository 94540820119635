import { Box, Stack } from '@chakra-ui/react'
import React from 'react'

// TODO: Move to the sidebar on full screen and highlight active header (https://nickymeuleman.netlify.app/blog/table-of-contents)

function TableOfContents(props) {
    return (
        <details>
            <Box as="summary" fontWeight={700}>Table of Contents</Box>
            {renderItems(props.items)}
        </details>
    )
}

function renderItems(items) {
    return (
        <Stack as="ol" listStyleType="none" paddingStart={8} my={1}>
            {items.map(item => (
                <Box key={item.url}>
                    <Box
                        as="a"
                        _hover={{ color: "blue.500" }}
                        _focusVisible={{ color: "blue.500" }}
                        textDecoration="underline"
                        href={item.url}
                    >
                        {item.title}
                    </Box>
                    {item.items && renderItems(item.items)}
                </Box>
            ))}
        </Stack>
    )
}

export { TableOfContents }