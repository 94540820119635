import React from 'react'
import { graphql } from 'gatsby'
import { Text, Container, Heading, Stack, HStack, StackDivider, Icon, usePrefersReducedMotion } from '@chakra-ui/react'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import SEO from '../components/seo'
import NewsletterForm from '../components/compounds/NewsletterForm'
import {
    FaClock,
    FaCalendar,
    FaVideo
} from "react-icons/fa"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { MotionBox } from '../components/atoms/MotionBox'
import { HitCounter } from '../components/compounds/HitCounter/HitCounter'
import { Box } from '@chakra-ui/react'
import { ThumbnailBadge } from '../components/atoms/ThumbnailBadge'
import { TableOfContents } from '../components/compounds/TableOfContents'

export default function BlogTemplate({ data }) {
    const post = data.mdx
    const {
        title,
        date,
        description,
        socialThumbnail,
        thumbnail,
        thumbnailCredit,
        hasHeaderThumbnail,
        headerThumbnailPosition,
        path,
        videosDurationsInMins,
    } = post.frontmatter

    const preferReducedMotion = usePrefersReducedMotion()

    const image = getImage(thumbnail)

    return (
        <>
            <SEO
                title={title}
                description={description}
                image={socialThumbnail?.publicURL}
            />
            {hasHeaderThumbnail && (
                <MotionBox
                    layoutId={!preferReducedMotion && `${title}-thumbnail`}
                    h={["65vw", null, "500px"]}
                    bgColor="blue.700"
                    userSelect="none"
                >
                    {thumbnailCredit && (
                        <ThumbnailBadge id={`${title}-thumbnail-badge`}>
                            Illustration by {thumbnailCredit}
                        </ThumbnailBadge>
                    )}
                    <GatsbyImage
                        image={image}
                        alt={thumbnail.name}
                        style={{
                            width: "100%",
                            maxWidth: "100vw",
                            height: "100%",
                        }}
                        imgStyle={{
                            objectFit: "cover",
                            objectPosition: headerThumbnailPosition || 'center',
                        }}
                    />
                </MotionBox>
            )}
            <Container maxW="3xl" py={12}>
                <Box mb={8}>
                    <BlogHeading
                        title={title}
                        date={date}
                        timeToRead={post.timeToRead}
                        videosDurationsInMins={videosDurationsInMins}
                    />
                </Box>
                {post?.tableOfContents?.items && (
                    <Box mb={4}>
                        <TableOfContents items={post.tableOfContents.items} />
                    </Box>
                )}
                <Stack spacing="0.75em" mb={2}>
                    <MDXRenderer>{post.body}</MDXRenderer>
                </Stack>
                <Box textAlign="right" mb={12}>
                    <HitCounter slug={path} />
                </Box>
                <NewsletterForm />
            </Container>
        </>
    )
}

function BlogHeading({ title, date, timeToRead, videosDurationsInMins }) {
    return (
        <Stack>
            <Heading as="h1" size="2xl">
                {title}
            </Heading>
            <Stack
                pt={2}
                direction={["column", null, "row"]}
                spacing={[1, null, 4]}
                divider={
                    <StackDivider
                        borderColor={{ lg: "gray.300" }}
                        _dark={{
                            borderColor: {
                                lg: "gray.600",
                            },
                        }}
                    />
                }
            >
                <HStack>
                    <Icon as={FaCalendar} color="gray.600" />
                    <Text as="i"> published on {date}</Text>
                </HStack>
                <HStack>
                    <Icon as={FaClock} color="gray.600" />
                    <Text as="i"> {timeToRead} min read</Text>
                </HStack>
                {videosDurationsInMins && (
                    <HStack>
                        <Icon as={FaVideo} color="gray.600" />{" "}
                        <Text as="i">{videosDurationsInMins} min videos</Text>
                    </HStack>
                )}
            </Stack>
        </Stack>
    )
}

export const _ = graphql`
    query BlogPost($slug: String!) {
        mdx(frontmatter: { path: { eq: $slug } }) {
            frontmatter {
                author
                date
                title
                path
                description
                hasHeaderThumbnail
                headerThumbnailPosition
                videosDurationsInMins
                socialThumbnail {
                    publicURL
                }
                thumbnailCredit
                thumbnail {
                    name
                    childImageSharp {
                        gatsbyImageData(
                            quality: 100
                            layout: FULL_WIDTH
                            placeholder: TRACED_SVG
                            tracedSVGOptions: {
                                color: "#2c5282"
                                background: "#bee3f8"
                            }
                        )
                    }
                }
            }
            body
            timeToRead
            tableOfContents
        }
    }
`
