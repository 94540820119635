import {
    Button,
    ButtonProps,
    useToken,
    useBreakpoint,
    forwardRef,
} from "@chakra-ui/react"
import { useMotionValue, useTransform, useMotionTemplate } from "framer-motion"
import React, { PropsWithChildren, useState } from "react"
import useSound from "use-sound"
import { MotionBox } from "../MotionBox"
import clickSound from "../../../assets/sounds/click.mp3"

const bottomOverlayXScaleFactor = {
    base: 1,
    sm: 1,
    md: 1.008,
    lg: 1.008,
}

type NiftyButtonProps = ButtonProps

const NiftyButton = forwardRef<PropsWithChildren<NiftyButtonProps>, "button">(
    function NiftyButton(
        {
            children,
            onTouchStart,
            onTouchEnd,
            onMouseDown,
            onMouseUp,
            onKeyPress,
            onKeyUp,
            onMouseMove,
            onMouseLeave,
            ...props
        },
        ref
    ) {
        const [isButtonPressed, setIsButtonPressed] = useState(false)
        const [play] = useSound(clickSound, {
            interrupt: true,
            playbackRate: isButtonPressed ? 0.6 : 0.9,
            volume: isButtonPressed ? 0.8 : 0.4,
        })

        function buttonClicked() {
            play()
            setIsButtonPressed(true)
        }

        function buttonReleased() {
            play()
            setIsButtonPressed(false)
        }

        const breakPoint = useBreakpoint("base")
        const isMobile = ['base', 'sm'].includes(breakPoint)

        const [variant, setVariant] = useState<
            "pressed" | "touched" | "initial"
        >("initial")

        const [borderColor] = useToken("colors", ["whiteAlpha.800"])
        const cursorX = useMotionValue(0.5)
        const cursorY = useMotionValue(0.5)
        const rotateX = useTransform(cursorY, [0, 1], [12, 5])
        const rotateY = useTransform(cursorX, [0, 1], [-10, 10])
        const shadowX = useTransform(cursorX, [0, 1], [8, -8])
        const shadowY = useTransform(cursorY, [0, 1], [4, 1])
        const transformOrigin = useMotionTemplate`calc(${cursorX} * 100%) calc(${cursorY} * 100%)`
        const shadow = useMotionTemplate`${shadowX}px calc(${shadowY}px * 2) calc(25px * var(--pressed-factor)) -5px rgba(0, 0, 0, 0.5), ${shadowX}px ${shadowY}px calc(10px * var(--pressed-factor)) -5px rgba(0, 0, 0, 0.1)`

        const bottomTransformOrigin = useMotionTemplate`calc(${useTransform(
            cursorX,
            [0, 0.49, 0.5, 0.51, 1],
            [0, 0.01, 0.5, 0.99, 1]
        )} * 100%) 50%`
        const currentBottomOverlayXScaleFactor =
            // @ts-expect-error
            bottomOverlayXScaleFactor[breakPoint] ||
            bottomOverlayXScaleFactor["base"]
        const bottomScaleXValue = useTransform(
            cursorX,
            [0, 0.5, 1],
            [
                currentBottomOverlayXScaleFactor,
                1,
                currentBottomOverlayXScaleFactor,
            ]
        )
        const bottomScaleX = useMotionTemplate`calc(${bottomScaleXValue} * var(--scale-factor, 1))`

        function updateCursorCoordinates(e: React.MouseEvent) {
            const node = e.target as HTMLElement
            const bounds = node.getBoundingClientRect()
            var [x, y] = [
                Math.max(
                    0,
                    Math.min(1, (e.clientX - bounds.left) / bounds.width)
                ),
                Math.max(
                    0,
                    Math.min(1, (e.clientY - bounds.top) / bounds.height)
                ),
            ]
            cursorX.set(x)
            cursorY.set(y)
        }

        function resetCursorCoordinates() {
            cursorX.set(0.5)
            cursorY.set(0.5)
        }

        return (
            <MotionBox
                ref={ref}
                as={Button}
                animate={variant}
                role="group"
                aria-label={children}
                onTouchStart={e => {
                    buttonClicked()
                    if (onTouchStart) onTouchStart(e)
                }}
                onTouchEnd={e => {
                    resetCursorCoordinates()
                    if (onTouchEnd) onTouchEnd(e)
                }}
                onMouseDown={e => {
                    buttonClicked()
                    if (onMouseDown) onMouseDown(e)
                }}
                onMouseUp={e => {
                    buttonReleased()
                    if (onMouseUp) onMouseUp(e)
                }}
                onKeyPress={e => {
                    if (
                        e?.target?.form?.checkValidity?.() &&
                        ["Enter", "Space"].includes(e.code)
                    ) {
                        if (variant !== "pressed") buttonClicked()
                        setVariant("pressed")
                    }
                    if (onKeyPress) onKeyPress(e)
                }}
                onKeyUp={e => {
                    if (["Enter", "Space"].includes(e.code)) {
                        buttonReleased()
                        setVariant("initial")
                    }
                    if (onKeyUp) onKeyUp(e)
                }}
                onMouseMove={e => {
                    updateCursorCoordinates(e)
                    if (onMouseMove) onMouseMove(e)
                }}
                onMouseLeave={e => {
                    resetCursorCoordinates(e)
                    if (onMouseLeave) onMouseLeave(e)
                }}
                position="relative"
                variant="unstyled"
                borderRadius="md"
                userSelect="none"
                isolation="isolate"
                whileHover="touched"
                whileTap="pressed"
                variants={{
                    initial: {
                        "--pressed-factor": 1,
                    },
                    touched: {
                        "--pressed-factor": 0.6,
                    },
                    pressed: {
                        "--pressed-factor": 0.3,
                    },
                }}
                shadow="var(--shadow)"
                style={{
                    "--shadow": shadow,
                    WebkitTapHighlightColor: "transparent",
                }}
                sx={{
                    "--pressed-factor": 1,
                    willChange: "transform, box-shadow",
                    perspective: "1000px",
                    "&:focus:not([data-focus-visible-added])": {
                        shadow: "var(--shadow)",
                    },
                }}
                _focusVisible={{
                    boxShadow: "outline",
                }}
                _hover={{
                    boxShadow: "var(--shadow)",
                }}
                _active={{
                    boxShadow: "var(--shadow)",
                }}
                {...props}
            >
                <MotionBox
                    sx={{
                        transformationStyle: "preserve-3d",
                    }}
                    initial={{
                        y: -8,
                        borderColor,
                    }}
                    style={
                        !isMobile && {
                            rotateY,
                            rotateX,
                            transformOrigin,
                        }
                    }
                    variants={{
                        touched: {
                            borderColor: "rgba(255, 255, 255, 0.4)",
                            y: -5,
                            transition: {
                                type: "spring",
                                damping: 4,
                            },
                        },
                        pressed: {
                            y: -1,
                            borderColor: "rgba(255, 255, 255, 0)",
                            transition: {
                                type: "spring",
                                duration: 0.02,
                            },
                        },
                    }}
                    transition={{
                        type: "spring",
                        duration: 0.5,
                    }}
                    p={4}
                    py={2}
                    w="full"
                    h="full"
                    borderRadius="inherit"
                    bgGradient="linear(to-b, blue.400, blue.500)"
                    borderTop="0.5px solid"
                    color="white"
                >
                    {children}
                </MotionBox>
                <MotionBox
                    style={{
                        transformOrigin: bottomTransformOrigin,
                        scaleX: bottomScaleX,
                    }}
                    borderRadius="inherit"
                    position="absolute"
                    inset={0}
                    bgGradient="linear(to-b, blue.600 88%, blue.700)"
                    zIndex={-1}
                />
            </MotionBox>
        )
    }
)

export { NiftyButton }
