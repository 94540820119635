import { Text } from "@chakra-ui/react"
import React, { useEffect } from "react"
import { useLocalStorage } from "../../../hooks/useLocalStorage"

type hitCounterProps = {
    slug: string
}

function HitCounter({ slug }: hitCounterProps) {
    const [hits, setHits] = useLocalStorage<number | undefined>(
        `${slug}-hit-count`,
        undefined,
        true
    )

    useEffect(() => {
        if (hits) {
            return
        }
        if (process.env.NODE_ENV === "development") {
            if (setHits) setHits(42)
            return
        }

        fetch(`/.netlify/functions/register-hit?slug=${slug}`)
            .then(res => res.json())
            .then(function (res) {
                if (typeof res.hits === "number" && setHits) {
                    setHits(res.hits)
                }
            })
    }, [slug])

    if (!hits || typeof hits === "undefined") {
        return null
    }

    return (
        <Text
            fontSize="xl"
            fontWeight="bold"
            color="gray.400"
            letterSpacing="tighter"
        >
            {new Intl.NumberFormat("en").format(+hits)} VIEWS
        </Text>
    )
}

export { HitCounter }
